<template>
  <div id="cashCollection">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="11" class="title-col">
        <div class="title">
          <h1>{{ $t("Transaction") }}</h1>
        </div>
      </v-col>
      <v-col cols="1"
        ><v-icon
          class="main-color"
          @click="EditTransaction"
          v-if="paymentObject.is_confirmed == 0"
          >edit</v-icon
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div class="box">
          <h4 class="second-title">{{ $t("Select Payment") }}</h4>
          <ul class="payment-methods text-center">
            <li class="text-center disabled">
              <p class="icon">
                <i class="fas fa-money-check"></i>
              </p>
              <p class="text">{{ $t("Visa") }}</p>
            </li>

            <li class="text-center">
              <p
                class="icon selectable"
                :class="paymentObject.type == 'Cash' ? 'active' : ''"
                @click="selectPaymentMethod('Cash')"
              >
                <i class="far fa-money-bill-alt"></i>
              </p>
              <p class="text">{{ $t("Cash") }}</p>
            </li>

            <li class="text-center">
              <p
                class="icon selectable"
                :class="paymentObject.type == 'Bank Transfer' ? 'active' : ''"
                @click="selectPaymentMethod('Bank Transfer')"
              >
                <i class="fas fa-dollar-sign"></i>
              </p>
              <p class="text">{{ $t("Bank Transfer") }}</p>
            </li>

            <li class="text-center">
              <p
                class="icon selectable"
                :class="paymentObject.type == 'Check' ? 'active' : ''"
                @click="selectPaymentMethod('Check')"
              >
                <i class="far fa-credit-card"></i>
              </p>
              <p class="text">{{ $t("Check") }}</p>
            </li>
          </ul>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="box">
          <v-row>
            <v-col cols="12" md="6">
              <p class="key">{{ $t("Transaction Id") }}</p>
              <p class="value">{{ paymentObject.transaction_id }}</p>
            </v-col>
            <v-col cols="10" md="6">
              <p class="key">{{ $t("Transaction Date") }}</p>

              <p class="value">{{ paymentObject.transaction_date }}</p>
            </v-col>

            <v-row>
              <v-col cols="12" md="6">
                <p class="key" v-if="paymentObject.type == 'Cash'">
                  {{ $t("Voucher Number") }}
                </p>
                <p class="key" v-if="paymentObject.type == 'Bank Transfer'">
                  {{ $t("Transfer Number") }}
                </p>
                <p class="key" v-if="paymentObject.type == 'Check'">
                  {{ $t("Check Number") }}
                </p>
                <p class="value">{{ paymentObject.transfer_number }}</p>
              </v-col>
              <v-col cols="6" md="6">
                <p class="key" v-if="paymentObject.type == 'Bank Transfer'">
                  {{ $t("Transfer Date") }}
                </p>
                <p class="key" v-if="paymentObject.type == 'Check'">
                  {{ $t("Check Date") }}
                </p>
                <p class="value">{{ paymentObject.transfer_date }}</p>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2">
        <h3 class="second-title">{{ $t("Payment Details") }}</h3>
      </v-col>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <label for="select payment" class="payment_value">{{
              $t("Cash Point")
            }}</label>
            <p class="value">{{ paymentObject.cash_point_name }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <label for="select payment" class="payment_value">{{
              $t("Partner")
            }}</label>
            <p class="value">{{ paymentObject.partner_name }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <label for="select payment" class="payment_value">{{
              $t("Code")
            }}</label>

            <p class="value">{{ paymentObject.partner_code }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="3"
            ><label for="select payment" class="payment_value">{{
              $t("Tax Registration Number")
            }}</label>
            <p class="value">{{ paymentObject.tax_registration }}</p>
          </v-col>
          <v-col cols="12">
            <label for="textarea" class="payment_value">{{
              $t("Notes")
            }}</label>
            <p class="value">{{ paymentObject.notes }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <hr class="basic" />

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="paymentObject.items"
          class="elevation-1 level1"
          loading-text="Loading... Please wait"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
    </v-row>

    <hr class="second" />

    <v-row>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ paymentObject.amount_before_tax }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong>
          {{ paymentObject.tax_amount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ paymentObject.total_amount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ paymentObject.total_after_discount }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-btn
          color="#7297FF"
          @click="confirmPayment"
          v-if="paymentObject.is_confirmed == 0"
          >{{ $t("Confirm Payment") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ViewGeneralPayment",
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      headers: [
        {
          text: this.$i18n.t("Code"),
          align: "start",
          sortable: false,
          value: "account_code",
        },
        { text: this.$i18n.t("Name"), value: "account_name", sortable: false },
        { text: this.$i18n.t("Amount"), value: "due_amount", sortable: false },
        { text: this.$i18n.t("Tax"), value: "taxes_names", sortable: false },
        { text: this.$i18n.t("Tax Amount"), value: "tax_amount", sortable: false },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        { text: this.$i18n.t("Net"), value: "net_amount", sortable: false },
      ],

      paymentObject: {
        type: "",
        partner_code: "",
        cash_point_name: "",
        transaction_date: "",
        transaction_id: "",
        transfer_number: "",
        transfer_date: "",
        items: [],
        partner_name: "",
        tax_registration: "",
        notes: "",
        amount_before_tax: "",
        tax_amount: "",
        total_amount: "",
        total_after_discount: "",
      },

      options: {
        itemsPerPage: 100,
      },
      id: null,
    };
  },
  methods: {
    confirmPayment() {
      axios
        .get(this.getApiUrl + "/general_payment/confirmPayment/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            location.reload();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
          }
        });
    },
    EditTransaction() {
      this.$router.push(
        {
          path: "/editGeneralPayment/" + this.id,
        },
        () => {}
      );
    },
    getPaymentData() {
      axios
        .get(this.getApiUrl + "/general_payment/show/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.paymentObject = response.data.data;
        });
    },
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    this.getPaymentData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}
.payment_value {
  color: #646464 !important;
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}
</style>
